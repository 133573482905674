import './DemoLabel.scss';

export default function DemoLabelView() {
  return (
    <div className='pa vc'>
      <div className='demo'>
        <img className='demo-img' alt='demo-tag' src='/../demo.png' />
      </div>
    </div>
  );
}
