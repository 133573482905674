import jwtInterceptor from 'interceptors/jwtInterceptor'
import { apiRanking, filterEmptyKeys, loadAbort } from 'utilities'

export const getRanking = (
  extend: string,
  page: number,
  limit: number,
  orderBy: string,
  date: string,
  selectedGroups: any,
  search?: string,
  challenge?: string,
  selectedSubgroups?: any
) => {
  const controller = loadAbort()
  if (orderBy === 'groupName') orderBy = 'group_name'
  if (orderBy === 'subgroupName') orderBy = 'subgroup_name'
  if (orderBy === '-totalActivities') orderBy = '-total_activities'
  if (extend === 'groups' || extend === 'subgroups') extend = `${extend}/`
  const params = {
    limit,
    offset: limit * page,
    search,
    from_date: date,
    challenge,
    ordering: orderBy,
    group_name: selectedGroups,
    subgroup_name: selectedSubgroups,
  }
  filterEmptyKeys(params)
  const url = apiRanking + extend
  return {
    call: jwtInterceptor.get(url, {
      signal: controller.signal,
      params: {
        ...params,
      },
      paramsSerializer: {
        indexes: null,
      },
    }),
    controller,
  }
}
