import jwtInterceptor from 'interceptors/jwtInterceptor'
import { News } from 'models'
import { apiNews, filterEmptyKeys, loadAbort } from 'utilities'

export const getNewsList = (
  limit: number,
  page: number,
  search: string,
  orderBy: string
) => {
  const controller = loadAbort()
  if (orderBy === 'name') orderBy = 'title'
  if (orderBy === '-createAt') orderBy = '-created_at'
  if (orderBy === '-state') orderBy = '-draft'
  const params = {
    limit,
    offset: page * limit,
    search,
    ordering: orderBy,
  }
  filterEmptyKeys(params)
  return {
    call: jwtInterceptor.get(apiNews, {
      signal: controller.signal,
      params,
    }),
    controller,
  }
}
export const getNews = (uuid: string) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.get<News>(`${apiNews}${uuid}`, {
      signal: controller.signal,
    }),
    controller,
  }
}
export const postNews = (data: {}) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.post<News>(apiNews, data, {
      signal: controller.signal,
    }),
    controller,
  }
}
export const putNews = (uuid: string, data: {}) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.put<News>(`${apiNews}${uuid}/`, data, {
      signal: controller.signal,
    }),
    controller,
  }
}
export const deleteNews = (uuid: string) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.delete(`${apiNews}${uuid}/`, {
      signal: controller.signal,
    }),
    controller,
  }
}
