import { Group, SubGroup } from '../models';
import { getGroups, getUserPermissions } from '../services';
import { useAsync, useFetchAndLoad } from './index';

import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { AppStore } from 'redux/store';

interface Subgroup {
  name: string;
  uuid: string;
  parent: string;
  parentUuid: string;
}

interface Cluster {
  name: string;
  uuid: string;
  subgroups?: Subgroup[];
}
interface Structure {
  groups: Array<Cluster>;
  subGroups: Array<Cluster>;
}

export const useGetStructure = () => {
  const userInfo = useSelector((store: AppStore) => store.user);
  const dashboard = useSelector((store: AppStore) => store.dashboard);
  const { callEndpoint } = useFetchAndLoad();
  const [listPermissions, setListPermissions] = useState<any[]>();
  const [groups, setGroups] = useState(new Map() as Map<string, Group>);
  const [structure, setStructure] = useState<Structure>({
    groups: [],
    subGroups: [],
  });
  const [subGroups, setSubGroups] = useState(
    new Map() as Map<string, SubGroup>
  );
  const [rowCount, setRowCount] = useState(0);
  const [groupsCurrentUser, setGroupsCurrentUser] = useState([]);
  const [subGroupsCurrentUser, setSubGroupsCurrentUser] = useState([]);
  const [currentUserAdmin, setCurrentUserAdmin] = useState();
  const [subgroupsOnlyGroup, setSubsgroupsOnlyGroup] = useState<any[]>();
  const getStructure = async () => {
    const getCount = await callEndpoint(getGroups(0, 1));
    const limitGroups = getCount.data.count + 10;
    return await callEndpoint(getGroups(0, limitGroups));
  };
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedSubgroup, setSelectedSubgroup] = useState('');
  const [selectedGroup2, setSelectedGroup2] = useState('');
  const [selectedSubgroup2, setSelectedSubgroup2] = useState('');

  const getPermissionsList = async () =>
    await callEndpoint(getUserPermissions(0, 500));

  const saveInfo = (data: any) => {
    const list = data.results;
    const groupData: Map<string, Group> = new Map(
      list.map((item: Group) => [item.uuid, item])
    );
    const subgroups: Map<string, SubGroup> = new Map();
    groupData.forEach((group: Group) => {
      group.subgroups.forEach((subgroup: SubGroup) => {
        const newSubgroup = {
          ...subgroup,
          parent: group.name,
          parentUuid: group.uuid,
        };
        subgroups.set(subgroup.uuid, newSubgroup);
      });
    });
    setGroups(groupData);
    setSubGroups(subgroups);
    setStructure({
      ...structure,
      groups: Array.from(groupData).map((group) => {
        return {
          name: group[1].name,
          uuid: group[1].uuid,
          subgroups: group[1].subgroups
            .map((subgroup) => {
              return {
                name: `[${group[1].name}]: ${subgroup.name}`,
                uuid: subgroup.uuid,
                parent: group[1].name,
                parentUuid: group[1].uuid,
              };
            })
            .filter(
              (subgroup) => subgroup.name !== `[${group[1].name}]: Default`
            ),
        };
      }),
      subGroups: Array.from(subgroups)
        .map((subgroup) => {
          return {
            name:
              Array.from(subgroups).length !== Array.from(groupData).length
                ? `[${subgroup[1].parent}]: ${subgroup[1].name}`
                : `${subgroup[1].parent}`,
            uuid: subgroup[1].uuid,
            parent: subgroup[1].parent,
            parentUuid: subgroup[1].parentUuid,
          };
        })
        .filter(
          (subgroup) => subgroup.name !== `[${subgroup.parent}]: Default`
        ),
    });
    return groupData;
  };

  const savePermissions = (data: any) => {
    setListPermissions(data.results);
    setRowCount(data?.count || 0);
    const permissionCurrentUser = data.results.find(
      (user: any) => user.user.uuid === userInfo.uuid
    );
    const DataGroupsCurrentUser = permissionCurrentUser?.groups_admins.map(
      (group: any) => {
        const dataGroup = groups.get(group);
        return { name: dataGroup?.name, uuid: dataGroup?.uuid };
      }
    );
    const DataSubGroupsCurrentUser =
      permissionCurrentUser?.subgroups_admins.map((subgroup: any) => {
        const dataSubGroup = subGroups.get(subgroup);
        return {
          name: `[${dataSubGroup?.parent}]: ${dataSubGroup?.name}`,
          uuid: dataSubGroup?.uuid,
        };
      });
    if (DataGroupsCurrentUser?.length === 1) {
      const dataOnlyGroup = groups.get(DataGroupsCurrentUser[0].uuid);
      const listSubgroups = dataOnlyGroup?.subgroups
        .map((subgroup) => {
          return {
            name: subgroup.name,
            uuid: subgroup.uuid,
            parent: DataGroupsCurrentUser[0].name,
          };
        })
        .filter((item) => item.name !== 'Default');
      setSubsgroupsOnlyGroup(listSubgroups);
    }
    if (DataGroupsCurrentUser?.length >= 0) {
      const subgroupsFromGroups = DataGroupsCurrentUser.map((group: any) => {
        const dataGroupFrom = groups.get(group.uuid);
        const listSubgroups = dataGroupFrom?.subgroups
          .map((subgroup) => {
            return {
              name: `[${dataGroupFrom.name}]: ${subgroup.name}`,
              uuid: subgroup.uuid,
              parent: dataGroupFrom.name,
            };
          })
          .filter((item) => !item.name.includes('Default'));
        return listSubgroups;
      });
      const totalList = [].concat.apply([], subgroupsFromGroups);
      let merge: any = [].concat.apply(totalList, DataSubGroupsCurrentUser);
      merge = merge.filter(
        (value: any, index: any, self: any) =>
          index === self.findIndex((t: any) => t?.uuid === value?.uuid)
      );
      setSubsgroupsOnlyGroup(merge);
    }

    if (Object.values(groups)) {
      setGroupsCurrentUser(DataGroupsCurrentUser);
    }
    if (Object.values(subGroups)) {
      setSubGroupsCurrentUser(DataSubGroupsCurrentUser);
    }
    setCurrentUserAdmin(permissionCurrentUser?.company_admin);
  };
  const selectedClusterName = () => {
    if (dashboard.selectedGroup) {
      const filteredArrayGroup = structure.groups.filter(
        (item: any) => item.uuid === dashboard.selectedGroup
      );
      const filteredArraySubgroup = structure.subGroups.filter(
        (item: any) => item.uuid === dashboard.selectedGroup
      );
      if (filteredArrayGroup.length > 0) {
        const groupName = filteredArrayGroup[0].name;
        setSelectedSubgroup('');
        setSelectedGroup(groupName);
      } else if (filteredArraySubgroup.length > 0) {
        const subgroupName = filteredArraySubgroup[0].name
          .split(':')[1]
          .slice(1);
        setSelectedGroup('');
        setSelectedSubgroup(subgroupName);
      }
    } else {
      setSelectedGroup('');
      setSelectedSubgroup('');
    }
  };

  useEffect(() => {
    selectedClusterName();
  }, [dashboard.selectedGroup]);

  const selectedClusterName2 = () => {
    if (dashboard.selectedGroup2) {
      const filteredArrayGroup = structure.groups.filter(
        (item: any) => item.uuid === dashboard.selectedGroup2
      );
      const filteredArraySubgroup = structure.subGroups.filter(
        (item: any) => item.uuid === dashboard.selectedGroup2
      );
      if (filteredArrayGroup.length > 0) {
        const groupName = filteredArrayGroup[0].name;
        setSelectedSubgroup2('');
        setSelectedGroup2(groupName);
      } else if (filteredArraySubgroup.length > 0) {
        const subgroupName = filteredArraySubgroup[0].name
          .split(':')[1]
          .slice(1);
        setSelectedGroup2('');
        setSelectedSubgroup2(subgroupName);
      }
    } else {
      setSelectedGroup2('');
      setSelectedSubgroup2('');
    }
  };

  useEffect(() => {
    selectedClusterName2();
  }, [dashboard.selectedGroup2]);

  useAsync(getStructure, saveInfo, () => {});
  useAsync(getPermissionsList, savePermissions, () => {}, [groups]);

  return {
    structure,
    groups,
    subGroups,
    listPermissions,
    rowCount,
    setRowCount,
    groupsCurrentUser,
    subGroupsCurrentUser,
    currentUserAdmin,
    subgroupsOnlyGroup,
    selectedGroup,
    selectedSubgroup,
    selectedGroup2,
    selectedSubgroup2,
  };
};
