import jwtInterceptor from 'interceptors/jwtInterceptor'
import { apiImages, loadAbort } from 'utilities'

export const postImage = (data: any) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.post(apiImages, data, {
      signal: controller.signal,
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: formData => formData,
    }),
    controller,
  }
}

export const getImages = (data: any) => {
  const controller = loadAbort()
  const { offset, tag, limit } = data
  return {
    call: jwtInterceptor.get(apiImages, {
      signal: controller.signal,
      params: { tags__name: tag, offset, limit },
    }),
    controller,
  }
}

export const getImage = (url: string) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.get(url, { signal: controller.signal }),
    controller,
  }
}
