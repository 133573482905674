import jwtInterceptor from 'interceptors/jwtInterceptor'
import { apiPrizes, apiUniquePrizes, loadAbort } from 'utilities'

export const getPrizes = () => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.get(apiPrizes, { signal: controller.signal }),
    controller,
  }
}

export const getUniquePrizes = () => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.get(apiUniquePrizes, { signal: controller.signal }),
  }
}

export const postPrize = (data: any) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.post(apiPrizes, data, { signal: controller.signal }),
    controller,
  }
}

export const getPrize = (uuid: string) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.get(`${apiPrizes}${uuid}/`, {
      signal: controller.signal,
    }),
    controller,
  }
}
