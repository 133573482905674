import jwtInterceptor from 'interceptors/jwtInterceptor'
import { Challenge } from 'models'
import { apiChallenges, filterEmptyKeys, loadAbort } from 'utilities'

export const getChallenges = (
  search: string,
  uri: string,
  page: number,
  limit: number,
  orderBy: string
) => {
  // const controller = loadAbort()
  if (orderBy === '-inscription') orderBy = '-visible_on'
  if (orderBy === '-state') orderBy = ''
  const params = {
    search,
    limit,
    offset: page * limit,
    ordering: orderBy,
  }
  filterEmptyKeys(params)
  return {
    call: jwtInterceptor.get<Challenge>(`${apiChallenges}${uri && uri + '/'}`, {
      // signal: controller.signal,
      params,
    }),
    // controller,
  }
}

export const getChallengeDetail = (id: string) => {
  const controller = loadAbort()
  const url = apiChallenges + id
  return {
    call: jwtInterceptor.get<Challenge>(url, { signal: controller.signal }),
    controller,
  }
}

export const postNewChallenge = (data: {}) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.post<Challenge>(apiChallenges, data, {
      signal: controller.signal,
    }),
    controller,
  }
}

export const getChallenge = (uuid: string) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.get<Challenge>(`${apiChallenges}${uuid}/`, {
      signal: controller.signal,
    }),
    controller,
  }
}

export const putChallenge = (uuid: string, data: {}) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.put<Challenge>(`${apiChallenges}${uuid}/`, data, {
      signal: controller.signal,
    }),
    controller,
  }
}

export const deleteChallenge = (uuid: string) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.delete(`${apiChallenges}${uuid}/`, {
      signal: controller.signal,
    }),
    controller,
  }
}
