import jwtInterceptor from 'interceptors/jwtInterceptor'
import { Group, SubGroup } from 'models'
import { apiGroups, apiSupGroups, loadAbort } from 'utilities'

export const getGroups = (offset: number, limit: number) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.get<Group>(
      `${apiGroups}?offset=${offset}&limit=${limit}`,
      {
        signal: controller.signal,
      }
    ),
    controller,
  }
}

export const postGroup = (data: Group) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.post<Group>(apiGroups, data, {
      signal: controller.signal,
    }),
    controller,
  }
}

export const postSubGroup = (data: any) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.post<SubGroup>(apiSupGroups, data, {
      signal: controller.signal,
    }),
    controller,
  }
}

export const getGroup = (uuid: string) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.get(`${apiGroups + uuid}/`, {
      signal: controller.signal,
    }),
    controller,
  }
}
export const getSubgroup = (uuid: string) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.get(`${apiSupGroups + uuid}/`, {
      signal: controller.signal,
    }),
    controller,
  }
}

export const putGroup = (data: any, uuid: string) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.put(`${apiGroups + uuid}/`, data, {
      signal: controller.signal,
    }),
    controller,
  }
}
export const putSubgroup = (data: any, uuid: string) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.put(`${apiSupGroups + uuid}/`, data, {
      signal: controller.signal,
    }),
    controller,
  }
}
