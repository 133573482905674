import { GridSortModel } from '@mui/x-data-grid'
import axios from 'axios'

export function nullToEmpty(data: any) {
  if (data === null) data = ''
  return data
}

export function nullToCero(data: any) {
  if (!data) return 0
  return data
}

export function nanToCero(result: any) {
  if (isNaN(result)) return 0
  return result
}

export function infinityToCero(result: any) {
  if (isFinite(result)) return result
  return 0
}

export function isEmptyOrSpaces(str: string) {
  if (str === null || str.match(/^\s*$/) !== null) return null
  return str
}

export function convertToCamelCase(name: string) {
  const words = name.split(' ')
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word.toLowerCase()
    }
    return word.charAt(0).toUpperCase() + word.slice(1)
  })
  const camelCaseName = camelCaseWords.join('')
  return camelCaseName
}

export function toSnakeCase(text: string) {
  return text
    .replace(/\s+/g, '_')
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .toLowerCase()
}

export function sortingTable(model: GridSortModel) {
  if (model.length > 0) {
    const field = model[0]?.field
    const sort = model[0]?.sort
    if (sort === 'asc') {
      return field
    } else {
      return '-' + field
    }
  }
  return ''
}

export function recogniseLink(text: string) {
  const regex = /\[([^\]]+)]\(([^)]+)\)/
  const match = text.match(regex)
  const linkText = match ? match[1] : ''
  const linkUrl = match ? match[2] : ''
  const parts = text.split(regex)
  return {
    linkText,
    linkUrl,
    parts,
  }
}

// export function capitalizeFirstLetter(word: string) {
//   if (word.length === 0) {
//     return '';
//   }
//   return word.charAt(0).toUpperCase() + word.slice(1);
// }

export function capitalizeFirstLetter(text: string) {
  const words = text.split(' ')
  const capitalizedWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  )
  const result = capitalizedWords.join(' ')

  return result
}

export const imgToBlob = async (url: string): Promise<string> => {
  try {
    const response = await axios.get(url, {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache',
      },
    })

    const imageBlob = response.data

    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = function () {
        const stringResult = reader.result as string
        resolve(stringResult)
      }

      reader.onerror = function (error) {
        reject(error)
      }

      reader.readAsDataURL(imageBlob)
    })
  } catch (error) {
    console.error(error)
    throw error
  }
}
