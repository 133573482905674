import { ViewportProvider } from './context/viewportProvider'
import store from './redux/store'
import Navigation from './routes/Navigation'
import colors from './sass/_colors.scss'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// PublicPrivateInterceptor();

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
    allVariants: {
      color: `${colors.colorBlack}`,
    },
  },
})

function App() {
  return (
    <Provider store={store}>
      <ViewportProvider>
        <ThemeProvider theme={theme}>
          <Navigation />
          <ToastContainer
            position='top-right'
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </ThemeProvider>
      </ViewportProvider>
    </Provider>
  )
}

export default App
