import jwtInterceptor from 'interceptors/jwtInterceptor'
import { apiGetActivities, loadAbort } from 'utilities'

export const getActivities = () => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.get(apiGetActivities, { signal: controller.signal }),
    controller,
  }
}
