import BasicLayout from '../layouts/BasicLayout'
import HomeLayout from '../layouts/HomeLayout'
import { Route } from '../models'

import React from 'react'

const Home = React.lazy(() => import('../pages/Home'))
const Dashboard = React.lazy(() => import('../pages/Dashboard'))
const ChallengesList = React.lazy(
  () => import('../pages/Challenges/ChallengesList')
)
const CreateChallenge = React.lazy(
  () => import('../pages/Challenges/CreateChallenge')
)
const RewardsList = React.lazy(() => import('../pages/Rewards/RewardsList'))
const CreateReward = React.lazy(() => import('../pages/Rewards/CreateReward'))
const Settings = React.lazy(() => import('../pages/Settings'))
const Company = React.lazy(() => import('../pages/Company'))
const Ranking = React.lazy(() => import('../pages/Ranking'))
const NewsList = React.lazy(() => import('../pages/News/NewsList'))
const CreateNews = React.lazy(() => import('../pages/News/CreateNews'))
const Error = React.lazy(() => import('../pages/Error'))
const BookingsList = React.lazy(() => import('../pages/Bookings/BookingsList'))
const CreateAsset = React.lazy(() => import('../pages/Bookings/CreateAsset'))
const Carpooling = React.lazy(() => import('../pages/Carpooling'))
const FAQs = React.lazy(() => import('../pages/FAQs'))
const SectionFAQs = React.lazy(() => import('../pages/FAQs/SectionFAQs'))
const Communication = React.lazy(() => import('../pages/Communication'))
const R30DEBKit = React.lazy(
  () => import('../pages/Communication/R30DEBKit/R30DEBKit')
)
const UrbanKit = React.lazy(
  () => import('../pages/Communication/UrbanKit/UrbanKit')
)
const TableChallengesCommunication = React.lazy(
  () => import('../pages/Communication/TableChallenges')
)
const ChallengesKit = React.lazy(
  () => import('../pages/Communication/ChallengesKit/ChallengesKit')
)
const Testing = React.lazy(() => import('../pages/Testing/Testing'))
const CommunicationLaunch = React.lazy(
  () => import('../pages/Communication/LaunchKit/LaunchKit')
)
const CommunicationCarpooling = React.lazy(
  () => import('../pages/Communication/CarpoolingKit/CarpoolingKit')
)

export const routes: Route[] = [
  {
    path: '/',
    page: Home,
    layout: HomeLayout,
  },
  {
    path: '*',
    page: Error,
    layout: HomeLayout,
  },
  {
    path: '/dashboard',
    page: Dashboard,
    layout: BasicLayout,
  },
  {
    path: '/dashboard/:uuid',
    page: Dashboard,
    layout: BasicLayout,
  },
  {
    path: '/challenges',
    page: ChallengesList,
    layout: BasicLayout,
  },
  {
    path: '/rewards',
    page: RewardsList,
    layout: BasicLayout,
  },
  {
    path: '/news',
    page: NewsList,
    layout: BasicLayout,
  },
  {
    path: '/challenges/create_challenge',
    page: CreateChallenge,
    layout: BasicLayout,
  },
  {
    path: '/challenges/:uuid',
    page: CreateChallenge,
    layout: BasicLayout,
  },
  {
    path: '/rewards/create_reward',
    page: CreateReward,
    layout: BasicLayout,
  },
  {
    path: '/rewards/:uuid',
    page: CreateReward,
    layout: BasicLayout,
  },
  {
    path: '/news/create_news',
    page: CreateNews,
    layout: BasicLayout,
  },
  {
    path: '/news/:uuid',
    page: CreateNews,
    layout: BasicLayout,
  },
  {
    path: '/company',
    page: Company,
    layout: BasicLayout,
  },
  {
    path: '/bookings',
    page: BookingsList,
    layout: BasicLayout,
  },
  {
    path: '/bookings/:uuid',
    page: CreateAsset,
    layout: BasicLayout,
  },
  {
    path: '/bookings/create_booking',
    page: CreateAsset,
    layout: BasicLayout,
  },
  {
    path: '/settings',
    page: Settings,
    layout: BasicLayout,
  },
  {
    path: '/ranking',
    page: Ranking,
    layout: BasicLayout,
  },
  {
    path: '/carpooling',
    page: Carpooling,
    layout: BasicLayout,
  },
  {
    path: '/faqs',
    page: FAQs,
    layout: BasicLayout,
  },
  {
    path: '/faqs/:section',
    page: SectionFAQs,
    layout: BasicLayout,
  },
  {
    path: '/communication',
    page: Communication,
    layout: BasicLayout,
  },
  {
    path: '/communicationkit/challenges',
    page: TableChallengesCommunication,
    layout: BasicLayout,
  },
  {
    path: '/communicationkit/challenges/:id',
    page: ChallengesKit,
    layout: BasicLayout,
  },
  {
    path: '/communicationkit/challenges/r30deb',
    page: R30DEBKit,
    layout: BasicLayout,
  },
  {
    path: '/communicationkit/challenges/urban',
    page: UrbanKit,
    layout: BasicLayout,
  },
  {
    path: '/communicationkit/launch',
    page: CommunicationLaunch,
    layout: BasicLayout,
  },
  {
    path: '/communicationkit/carpooling',
    page: CommunicationCarpooling,
    layout: BasicLayout,
  },
  {
    path: '/testing',
    page: Testing,
    layout: BasicLayout,
  },
]
