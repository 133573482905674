import jwtInterceptor from 'interceptors/jwtInterceptor'
import {
  apiCompanyAdmin,
  apiCompanyConfig,
  apiCompanyOptions,
  apiCompanyStyle,
  apiCompanyViews,
  loadAbort,
} from 'utilities'

const controller = loadAbort()

export const getCompanyAdmin = () => {
  return {
    call: jwtInterceptor.get(apiCompanyAdmin, { signal: controller.signal }),
    controller,
  }
}
export const getCompanyInfo = () => {
  return {
    call: jwtInterceptor.get(apiCompanyViews, { signal: controller.signal }),
    controller,
  }
}
export const getCompanyOptions = () => {
  return {
    call: jwtInterceptor.get(apiCompanyOptions, { signal: controller.signal }),
    controller,
  }
}
export const patchCompanyConfig = (data: any) => {
  return {
    call: jwtInterceptor.patch(apiCompanyConfig, data, {
      signal: controller.signal,
    }),
    controller,
  }
}
export const getCompanyStyle = () => {
  return {
    call: jwtInterceptor.get(apiCompanyStyle, { signal: controller.signal }),
    controller,
  }
}
export const postCompanyStyle = (data: any) => {
  return {
    call: jwtInterceptor.post(apiCompanyStyle, data, {
      signal: controller.signal,
    }),
    controller,
  }
}
